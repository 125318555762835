<template>
   <div id="index" style="height: 100%" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" :color="decorationColor2" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">机构</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#e94e46', '#ffffff']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" :color="decorationColor2" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s" @click="onTapge('/img')">
              <span class="react-left"></span>
              <!-- bg-color-blue -->
              <span class="text">图片</span>
            </div>
            <div class="react-left ml-3 bg-color-red" @click="onTapge('/instion')">
              <span class="text">机构</span>
            </div>
            <div class="react-left ml-3" @click="onTapge('/doctor')">
              <span class="text">医生</span>
            </div>
            <div class="react-left ml-3" @click="onTapge('/auth')">
              <span class="text">权限</span>
            </div>
            <div class="react-left ml-3" @click="onTapge('/log')">
              <span class="text">日志</span>
            </div>
          </div>
          <!-- @click="onTapge('/index')" -->
          <div class="d-flex aside-width">
            <div class="react-right mr-3" @click="onTapge('/statistics')">
              <!-- <span class="text fw-b">AI质控</span> -->
              <span class="text">统计</span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="text" style="margin-right: 20px;cursor: pointer;" @click="loginOut">退出</span>
              <span class="react-after"></span>
              <span class="text"
              style="color:black"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
            <!-- <div class="content-box">
            </div> -->
            <!-- <dv-border-box-8 :dur="20" :color="decorationColor"> -->
                <div class="content-box-height">
                  <InstionTable />
                </div>
            <!-- </dv-border-box-8> -->

          <!-- 第四行数据 -->
          <!-- <div class="bottom-box">
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '../utils/index.js'
import InstionTable from "@/components/instion/index.vue"
export default {
  components: {
    InstionTable
  },
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#ffffff', '#e94e46'],
      decorationColor2: ['#e94e46', '#ffffff'],
      titleTips:{
        0: '四川省AI质控数据监测中心',
        1: '贵州省AI质控数据监测中心',
        2: '重庆市AI质控数据监测中心'
      },
      titleType: 0
    }
  },
   mounted() {
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    onTapge(url) {
      this.$router.replace(url);
    },
    loginOut() {
      sessionStorage.removeItem('uetk');
      this.$router.replace("/login");
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
.content-box-height {
    height: calc(100vh - 132px);
    width: 100%;
    overflow: auto;
}
</style>